import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './yangiliklar.scss'
import Rectangle79 from './../../../../img/Rectangle79.png'
gsap.registerPlugin(ScrollTrigger)
function Yangiliklar() {
  useEffect(() => {
    const tinchlikplaza_yangiliklar_col_2 = document.querySelectorAll(
      '.tinchlikplaza_yangiliklar_col_2>div'
    )
    tinchlikplaza_yangiliklar_col_2.forEach(tinchlikplaza_yangiliklar_col_2_timeline)
    function tinchlikplaza_yangiliklar_col_2_timeline(project, index) {
      gsap.to(project, {
        duration: 1,
        y: 0,
        ease: 'ease-in',
        scrollTrigger: {
          trigger: project,
          markers: false,
          scrub: 1,
          start: 'top 100%',
          end: 'top 150%',
        },
      })
    }
  })
  return (
    <div className='tinchlikplaza_yangiliklar'>
      <div className='tinchlikplaza_yangiliklar_col_1'>
        <img src={Rectangle79} alt='Rectangle79' />
        <div>
          <h1>Mart 05.2021</h1>
          <h3>
            «Emlanmaganlarga juda og‘ir bo‘lyapti» - Mirziyoyev aholini yana bir bor
            vaksina olishga chaqirdi
          </h3>
          <p>
            «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim: koronavirus
            pandemiyasi 10 yil davom etishi taxmin qilinmoqda. Biz endi bu virus
            bilan yashashni o‘rganishimiz zarur.
          </p>
        </div>
      </div>
      <div className='tinchlikplaza_yangiliklar_col_2'>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
        <div>
          <img src={Rectangle79} alt='Rectangle79' />
          <div>
            <h1>Mart 05.2021</h1>
            <h3>«Emlanmaganlarga juda og‘ir bo‘lyapti»</h3>
            <p>
              «Men jahonning yetakchi olimlari tadqiqotlari bilan qiziqdim:
              koronavirus pandemiyasi 10 yil davom etishi taxmin qilinmoqda.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Yangiliklar
