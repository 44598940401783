import React from 'react'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './footer.scss'
import Yer1 from './../../img/yer-1.svg'
import Yer2 from './../../img/yer-2.svg'
import Palmutlar from './../../img/palm-utlar.svg'
import Tuyalar from './../../img/tuyalar.svg'
import Logo from './../../img/logo.png'
import Quyosh from './../../img/quyosh.svg'

function Footer() {
  const { t } = useTranslation()
  return (
    <div className='tinchlikplaza_footer'>
      <img src={Yer2} alt='Yer2' className='Yer2' />
      <img src={Yer1} alt='Yer1' />
      <img src={Palmutlar} alt='Palmutlar' className='Palmutlar' />
      <img src={Tuyalar} alt='Tuyalar' className='Tuyalar' />
      <img src={Quyosh} alt='Quyosh' className='Quyosh' />
      <div>
        <div>
          <Link>
            <img src={Logo} alt='Logo' />
          </Link>
          <p>{t('Bizni kuzatib boring')}:</p>
          <div>
            <Link>
              <svg
                width='8'
                height='14'
                viewBox='0 0 8 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.36155 7.6459L7.74136 5.21579H5.36663V3.63881C5.36663 2.97398 5.69836 2.32593 6.76193 2.32593H7.84152V0.256949C7.84152 0.256949 6.86182 0.0927734 5.92512 0.0927734C3.96946 0.0927734 2.69115 1.25669 2.69115 3.3637V5.21579H0.517273V7.6459H2.69115V13.5206H5.36663V7.6459H7.36155Z'
                  fill='#381955'
                />
              </svg>
            </Link>
            <Link>
              <svg
                width='14'
                height='13'
                viewBox='0 0 14 13'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.4413 3.27067C12.4498 3.40079 12.4498 3.53094 12.4498 3.66105C12.4498 7.62988 9.68079 12.2029 4.6198 12.2029C3.0606 12.2029 1.61219 11.7103 0.393799 10.8552C0.615331 10.883 0.828313 10.8923 1.05837 10.8923C2.34489 10.8923 3.52921 10.4183 4.47495 9.60969C3.26509 9.58179 2.25119 8.71739 1.90185 7.52766C2.07227 7.55552 2.24266 7.57412 2.4216 7.57412C2.66868 7.57412 2.91578 7.53693 3.14581 7.47189C1.88483 7.19303 0.939066 5.98473 0.939066 4.52546V4.48829C1.30542 4.71137 1.73146 4.85079 2.183 4.86936C1.44174 4.33025 0.956113 3.41009 0.956113 2.36907C0.956113 1.8114 1.09241 1.30019 1.33099 0.854038C2.6857 2.6758 4.72203 3.8655 7.00541 3.99565C6.96282 3.77258 6.93725 3.54023 6.93725 3.30786C6.93725 1.65338 8.16416 0.305664 9.68926 0.305664C10.4816 0.305664 11.1973 0.668157 11.7 1.25372C12.322 1.1236 12.9184 0.872635 13.4467 0.528739C13.2421 1.22586 12.8076 1.81143 12.2368 2.18319C12.7906 2.11816 13.3274 1.95082 13.8215 1.71847C13.4467 2.31331 12.9781 2.84309 12.4413 3.27067Z'
                  fill='#381955'
                />
              </svg>
            </Link>
            <Link>
              <svg
                width='14'
                height='13'
                viewBox='0 0 28 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M26.11 0.157806L1.30743 9.93284C-0.38525 10.6277 -0.375457 11.5928 0.99687 12.0231L7.36469 14.0533L22.098 4.55278C22.7946 4.11958 23.4311 4.35262 22.9079 4.82729L10.9711 15.8376H10.9683L10.9711 15.839L10.5318 22.5473C11.1753 22.5473 11.4593 22.2456 11.8202 21.8896L14.9132 18.8157L21.3468 23.6725C22.533 24.3402 23.385 23.997 23.6801 22.5502L27.9034 2.20803C28.3357 0.436602 27.2418 -0.365474 26.11 0.157806Z'
                  fill='#381955'
                />
              </svg>
            </Link>
            <Link>
              <svg
                width='17'
                height='17'
                viewBox='0 0 17 17'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M12.3392 7.61933C11.9199 5.8343 10.322 4.51096 8.40686 4.51096C6.17074 4.51096 4.36709 6.31502 4.36709 8.55163C4.36709 8.86838 4.40326 9.17646 4.47172 9.47197C4.88664 11.263 6.48743 12.5923 8.40686 12.5923C10.643 12.5923 12.4466 10.7882 12.4466 8.55163C12.4466 8.2306 12.4095 7.91848 12.3392 7.61933ZM12.6119 5.2884C13.1323 5.2884 13.5542 4.86992 13.5542 4.34593C13.5542 3.82546 13.1323 3.40346 12.6119 3.40346C12.0916 3.40346 11.6697 3.82546 11.6697 4.34593C11.6697 4.8664 12.0881 5.2884 12.6119 5.2884ZM14.957 2.0003C15.8817 2.92167 16.17 4.03998 16.2298 5.30247C16.3036 6.60364 16.3036 10.5001 16.2298 11.8013C16.1665 13.0638 15.8782 14.1821 14.957 15.1035C14.0359 16.0284 12.9178 16.3167 11.6556 16.3765C10.3547 16.4504 6.45559 16.4504 5.15471 16.3765C3.8925 16.3132 2.77796 16.0248 1.85328 15.1035C0.928596 14.1821 0.640292 13.0638 0.580522 11.8013C0.506688 10.5001 0.506688 6.60013 0.580522 5.29895C0.643808 4.03646 0.928596 2.91816 1.85328 1.99679C2.77796 1.07541 3.89602 0.787046 5.15471 0.727263C6.45559 0.653412 10.3547 0.653412 11.6556 0.727263C12.9178 0.790563 14.0359 1.07893 14.957 2.0003Z'
                  fill='#381955'
                />
              </svg>
            </Link>
          </div>
        </div>
        <div>
          <h1>{t('Tinchlik Plaza')}</h1>
          <Link to='/xonalar'>{t('Xonalar')}</Link>
          <Link to='/restorant'>{t('Mehmonxona')}</Link>
          <Link to='/fitnes'>{t('Fitness & Spa')}</Link>
        </div>
        <div>
          <h1>{t('Media')}</h1>
          <Link to='/media'>{t('Gallereya')}</Link>
          <Link to='/media'>{t('Yangiliklar')}</Link>
        </div>
        <div>
          <h1>{t('Aloqa')}</h1>
          <p>{t('Mehmonxona')}</p>
          <a href='tel:998995999999'>+998 99 599 99 99</a>
          <p>{t('Fitness & Spa')}</p>
          <a href='tel:998995999999'>+998 99 599 99 99</a>
        </div>
      </div>
      <a href='https://softex.uz'>
        {t('Sayt yaratuvchi')} <span>Softex®</span>
      </a>
    </div>
  )
}

export default Footer
