import React from 'react'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import './media.scss'
import Yangiliklar from './yangiliklar/yangiliklar'
import Gallery from './gallery/gallery'

const { TabPane } = Tabs
function Media() {
  const { t } = useTranslation()
  return (
    <div className='tinchlikplaza_media'>
      <h1>{t('Media')}</h1>
      <Tabs defaultActiveKey='1'>
        <TabPane tab={t('Yangiliklar')} key='1'>
          <Yangiliklar />
        </TabPane>
        <TabPane tab={t('Gallereya')} key='2'>
          <Gallery />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Media
