import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './restaran.scss'
import Resimg from './../../../img/S1291.png'
import Plaza_logo from './../../../img/plaza_logo.png'
import Group160 from './../../../img/Group160.png'
import Group161 from './../../../img/Group161.png'
import Group162 from './../../../img/Group162.png'
import Group164 from './../../../img/Group164.png'
import Group165 from './../../../img/Group165.png'
import Group163 from './../../../img/Group163.png'
import Group168 from './../../../img/Group168.png'
import Group169 from './../../../img/Group169.png'

gsap.registerPlugin(ScrollTrigger)
function Restaran() {
  const { t } = useTranslation()
  useEffect(() => {
    const projectTriggers = document.querySelectorAll(
      '.tinchlikplaza_restorant > img'
    )
    const tinchlikplaza_restorant_col_1 = document.querySelectorAll(
      '.tinchlikplaza_restorant_col_1'
    )
    projectTriggers.forEach(addTimeline)
    tinchlikplaza_restorant_col_1.forEach(tinchlikplaza_restorant_col_1_timeline)
    function addTimeline(project, index) {
      gsap.to(project, {
        duration: 1,
        scale: '1',
        ease: 'ease-in',
        scrollTrigger: {
          trigger: project,
          markers: false,
          scrub: 2,
          start: 'top 80%',
          end: 'top 100%',
        },
      })
    }
    function tinchlikplaza_restorant_col_1_timeline(project, index) {
      gsap.to(project, {
        duration: 1,
        left: 0,
        ease: 'bounce',
        scrollTrigger: {
          trigger: project,
          markers: false,
          scrub: 2,
          start: 'top 100%',
          end: 'top 150%',
        },
      })
    }
  })
  return (
    <div className='tinchlikplaza_restorant'>
      <img src={Group160} alt='Group160' className='Group160' />
      <img src={Group161} alt='Group161' className='Group161' />
      <img src={Group162} alt='Group162' className='Group162' />
      <img src={Group164} alt='Group164' className='Group164' />
      <img src={Group165} alt='Group165' className='Group165' />
      <img src={Group163} alt='Group163' className='Group163' />
      <img src={Group168} alt='Group168' className='Group168' />
      <img src={Group169} alt='Group169' className='Group169' />
      <h1>{t('Restoran')}</h1>

      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('TINCHLIK PLAZA restorani')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('Panoramali restoran')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('Banket zali')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('La Peace Terrace')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('Yozgi veranda')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
      <div className='tinchlikplaza_restorant_col_1'>
        <img src={Resimg} alt='Resimg' />
        <div>
          <h1>{t('Rustik’s PUB')}</h1>
          <p>
            Уютная атмосфера, комфортные условия, качественное обслуживание,
            современный и неповторимый дизайн интерьера. Развлекательные
            шоу-программы, живая музыка, специальное меню от шеф-повара, и конечно же
            индивидуальный подход к каждому клиенту
          </p>
          <img src={Plaza_logo} alt='Plaza_logo' />
        </div>
      </div>
    </div>
  )
}

export default Restaran
