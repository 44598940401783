import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './fitness.scss'
import Fitness_img from './../../../img/fitness.png'
import fitness_1_101 from './../../../img/fitness_1_101.png'
import fitness_1_91 from './../../../img/fitness_1_91.png'
import Flower from './../../../img/flower.svg'
import spa_1_3 from './../../../img/spa_1_3.png'
import spa_1_4 from './../../../img/spa_1_4.png'
import fitness1 from './../../../img/fitness1.png'
import fitness2 from './../../../img/fitness2.png'
import big_flower_1 from './../../../img/big_flower_1.png'
import big_flower_2 from './../../../img/big_flower_2.png'
gsap.registerPlugin(ScrollTrigger)
function Fitness() {
  const { t } = useTranslation()
  useEffect(() => {
    gsap.to('.tinchlikplaza_fitness_col_1>div:nth-child(2)>p', {
      duration: 1,
      x: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.tinchlikplaza_fitness_col_1>div:nth-child(2)>p',
        markers: false,
        scrub: 1,
        start: 'top 100%',
        end: 'top 150%',
      },
    })
    gsap.to('.tinchlikplaza_fitness_col_2>img', {
      duration: 1,
      y: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.tinchlikplaza_fitness_col_2>img',
        markers: false,
        scrub: 1,
        start: 'top 120%',
        end: 'top 170%',
      },
    })
    gsap.to('.tinchlikplaza_fitness_col_3>div>div', {
      duration: 1,
      y: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.tinchlikplaza_fitness_col_3>div>div',
        markers: false,
        scrub: 1,
        start: 'top 120%',
        end: 'top 170%',
      },
    })
    gsap.to('.tinchlikplaza_fitness_col_4>div:nth-child(4)>img', {
      duration: 1,
      x: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.tinchlikplaza_fitness_col_4>div:nth-child(4)>img',
        markers: false,
        scrub: 1,
        start: 'top 100%',
        end: 'top 150%',
      },
    })
    gsap.to('.tinchlikplaza_fitness>img:nth-child(1)', {
      duration: 1,
      width: '200%',
      height: '50vh',
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.tinchlikplaza_fitness>img:nth-child(1)',
        markers: false,
        scrub: 0.5,
        start: 'top 0%',
      },
    })
  })
  return (
    <div className='tinchlikplaza_fitness'>
      <img src={Fitness_img} alt='Fitness' />
      <img src={fitness1} alt='fitness1' className='fitness1' />
      <img src={fitness2} alt='fitness2' className='fitness2' />
      <img src={big_flower_1} alt='big_flower_1' className='big_flower_1' />
      <img src={big_flower_2} alt='big_flower_2' className='big_flower_2' />
      <div className='tinchlikplaza_fitness_col_1'>
        <div>
          <p>{t('Tinchlik Plaza')}</p>
          <h1>{t('Fitnes - maskan')}</h1>
        </div>
        <div>
          <p>
            {t(
              'Sizning xizmatingizda zamonaviy va barcha qulayliklarga ega sport maydoni, trenajor xonalari va fitnes zali mavjud'
            )}
          </p>
          <p>
            {t(
              'Mijozlar uchun individual mashg’ulotlar, reablitatsiya uchun maxsus dasturlar hamda sog’lom turmush tarzini yuritish kurslari ham mavjud'
            )}
          </p>
        </div>
      </div>
      <div className='tinchlikplaza_fitness_col_2'>
        <img src={fitness_1_101} alt='fitness_1_101' />
        <img src={fitness_1_91} alt='fitness_1_91' />
      </div>
      <div className='tinchlikplaza_fitness_col_3'>
        <h1>{t('Fitnes zal narxlari')}</h1>
        <div>
          <div>
            <h1>
              25 000 {t('so’m')} / {t('kun')}
            </h1>
            <p>{t('Group classes')}</p>
            <p>{t('Discuss fitness goals')}</p>
            <p>{t('Group trainer')}</p>
            <p>{t('Fitness orintation')}</p>
          </div>
          <div>
            <h1>
              250 000 {t('so’m')} / {t('oy')}
            </h1>
            <h3>{t('1 yillik to‘lov qilinsa')}</h3>
            <p>{t('Group classes')}</p>
            <p>{t('Discuss fitness goals')}</p>
            <p>{t('Group trainer')}</p>
            <p>{t('Fitness orintation')}</p>
          </div>
          <div>
            <h1>
              350 000 {t('so’m')} / {t('oy')}
            </h1>
            <p>{t('Group classes')}</p>
            <p>{t('Discuss fitness goals')}</p>
            <p>{t('Group trainer')}</p>
            <p>{t('Fitness orintation')}</p>
          </div>
        </div>
      </div>
      <div className='tinchlikplaza_fitness_col_4'>
        <div>
          <p>{t('Tinchlik Plaza')}</p>
          <h1>{t('Sauna & SPA')}</h1>
        </div>
        <p>
          {t(
            'Zamonaviy SPA-salonda 14-metrli basseyn, massaj xonasi, turk hammomi, sauna, fin hammomi, jakuzi va bilyard xonalari mavjud. Malakali mutaxassislar tomonidan ko’plab sog’lomlashtiriuvchi dasturlar ham taqdim etiladi'
          )}
          :
        </p>
        <div className='flower'>
          <div>
            <p>
              <img src={Flower} alt='Flower' />
              {t('klassik uslubdagi bali massaji')};
            </p>
            <p>
              <img src={Flower} alt='Flower' />
              {t('shiatsu')};
            </p>
          </div>
          <div>
            <p>
              <img src={Flower} alt='Flower' />
              {t('issiq toshlar bilan massaj')};
            </p>
            <p>
              <img src={Flower} alt='Flower' />
              {t('aromaterapiya va ko’plab boshqa SPA-dasturlar')}
            </p>
          </div>
        </div>
        <div>
          <img src={spa_1_3} alt='spa_1_3' />
          <img src={spa_1_4} alt='spa_1_4' />
        </div>
      </div>
    </div>
  )
}

export default Fitness
