import React, { useEffect } from 'react'
import { Rate } from 'antd'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Carousel } from '3d-react-carousal'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import './home.scss'
import img2 from './../../../img/971.png'
import img1 from './../../../img/1012.png'
import BG from './../../../img/bg.png'
import Bulut from './../../../img/bulut.png'
import Plaza from './../../../img/plaza.png'
import Ung from './../../../img/ung.png'
import Chap from './../../../img/chap.png'
import Fitnes from './../../../img/Fitnes.png'
import Restourant from './../../../img/Restourant.png'
import Spa from './../../../img/Spa.png'
import Love from './../../../img/Love.png'
import Parking from './../../../img/Parking.png'
import gym from './../../../img/gym.png'
import Group156 from './../../../img/Group156.png'
import Group157 from './../../../img/Group157.png'
import Group155 from './../../../img/Group155.png'
import Ellipse38 from './../../../img/Ellipse38.png'
import Clouds from './../../../img/cloud_PNG24.png'

gsap.registerPlugin(ScrollTrigger)

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

let slides = [
  <div className='slides'>
    <img src={Ellipse38} alt='Ellipse38' />
    <h1>Munisa Rizayeva</h1>
    <h3>Xonanda</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus varius risus
      odio porta id. Quis in vitae nisi tortor purus praesent. Facilisi tortor turpis
      pharetra, ullamcorper sodales. Id mi turpis neque, aliquam. Sed molestie mi
      congue enim. Commodo viverra tortor eu non. A, amet scelerisque placerat turpis
      in sit eget vitae. Euismod purus odio tristique ultrices eu nibh in tellus.
    </p>
    <div>
      <Rate defaultValue='5' />
    </div>
  </div>,
  <div className='slides'>
    <img src={Ellipse38} alt='Ellipse38' />
    <h1>Munisa Rizayeva</h1>
    <h3>Xonanda</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus varius risus
      odio porta id. Quis in vitae nisi tortor purus praesent. Facilisi tortor turpis
      pharetra, ullamcorper sodales. Id mi turpis neque, aliquam. Sed molestie mi
      congue enim. Commodo viverra tortor eu non. A, amet scelerisque placerat turpis
      in sit eget vitae. Euismod purus odio tristique ultrices eu nibh in tellus.
    </p>
    <div>
      <Rate defaultValue='5' />
    </div>
  </div>,
  <div className='slides'>
    <img src={Ellipse38} alt='Ellipse38' />
    <h1>Munisa Rizayeva</h1>
    <h3>Xonanda</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus varius risus
      odio porta id. Quis in vitae nisi tortor purus praesent. Facilisi tortor turpis
      pharetra, ullamcorper sodales. Id mi turpis neque, aliquam. Sed molestie mi
      congue enim. Commodo viverra tortor eu non. A, amet scelerisque placerat turpis
      in sit eget vitae. Euismod purus odio tristique ultrices eu nibh in tellus.
    </p>
    <div>
      <Rate defaultValue='5' />
    </div>
  </div>,
  <div className='slides'>
    <img src={Ellipse38} alt='Ellipse38' />
    <h1>Munisa Rizayeva</h1>
    <h3>Xonanda</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus varius risus
      odio porta id. Quis in vitae nisi tortor purus praesent. Facilisi tortor turpis
      pharetra, ullamcorper sodales. Id mi turpis neque, aliquam. Sed molestie mi
      congue enim. Commodo viverra tortor eu non. A, amet scelerisque placerat turpis
      in sit eget vitae. Euismod purus odio tristique ultrices eu nibh in tellus.
    </p>
    <div>
      <Rate defaultValue='5' />
    </div>
  </div>,
]

function Home() {
  const { t } = useTranslation()
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  useEffect(() => {
    gsap.to('.plaza', {
      duration: 1,
      top: '400',
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.plaza',
        markers: false,
        scrub: 1,
        start: 'top 0%',
        end: 'bottom 0%',
      },
    })
    gsap.to('.bulut', {
      duration: 1,
      top: '-200',
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.bulut',
        markers: false,
        scrub: 1,
        start: 'top 0%',
        end: 'bottom 80%',
      },
    })
    gsap.to('.palma', {
      duration: 1,
      top: '300px',
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.palma',
        markers: false,
        scrub: 1,
        start: 'top 0%',
        end: 'bottom 0%',
      },
    })
    gsap.to('.Group156', {
      duration: 1,
      scale: 1,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.Group156',
        scrub: 1,
        start: 'top 100%',
        end: 'top 200%',
      },
    })
    gsap.to('.Group155', {
      duration: 1,
      scale: 1,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.Group155',
        scrub: 1,
        start: 'top 100%',
        end: 'top 200%',
      },
    })
    gsap.to('.Group157', {
      duration: 1,
      scale: 1,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.Group157',
        scrub: 1,
        start: 'top 100%',
        end: 'top 200%',
      },
    })
    gsap.to('.Group158', {
      duration: 1,
      scale: 1,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.Group158',
        scrub: 1,
        start: 'top 100%',
        end: 'top 200%',
      },
    })
  })

  function parallax(e) {
    document.querySelectorAll('.layer').forEach((layer) => {
      const speed = layer.getAttribute('data-speed')
      const x = (window.innerWidth - e.pageX * speed) / 100
      const y = (window.innerHeight - e.pageY * speed) / 100
      layer.style.transform = `translateX(${x}px) translateY(${y}px)`
    })
  }
  return (
    <div className='tinchlikplaza_home'>
      <div className='tinchlikplaza_home_imgs' onMouseMove={parallax}>
        <img src={BG} alt='BG' />
        <img src={Bulut} alt='Bulut' data-speed='10' className='layer bulut' />
        <img src={Plaza} alt='Plaza' data-speed='6' className='layer plaza' />
        <img src={Chap} alt='Chap' data-speed='8' className='layer palma' />
        <img src={Ung} alt='Ung' data-speed='8' className='layer palma' />
      </div>
      <div className='tinchlikplaza_home_rectangel'>
        <div className='clouds' onMouseMove={parallax}>
          <img className='fakecloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
          <img className='cloud' src={Clouds} alt='Clouds' />
        </div>

        <img src={Group156} className='Group156' alt='Group156' />
        <img src={Group157} className='Group157' alt='Group157' />
        <img src={Group157} className='Group158' alt='Group157' />
        <img src={Group155} className='Group155' alt='Group155' />

        <div className='tinchlikplaza_home_rectangel_col_1'>
          <h3>Tinchlik Plaza</h3>
          <p>{t('Xush kelibsiz!')}</p>
        </div>
        <div className='tinchlikplaza_home_rectangel_col_2'>
          <p>
            {t(
              'Siz sayohatga chiqib, Navoiy shahriga tashrif buyurdingizmi? Yoki ish yuzasidan shahrimizda mehmonmisiz? Balki oilaviy dam olish uchun qulay maskan istarsiz!'
            )}
            <br />
            <br />
            <span>{t('Tinchlik Plaza')} </span>
            {t('mehmonxonasi aynan siz uchun!')}
          </p>
          <p>
            {t(
              'Mehmonxona shahrining markaziy qismida joylashgan bo’lib, tashrif buyuruvchi mijozlar diqqatga sazovor tarixiy-madaniy yodgorliklar, do’konlar, istirohat bog’lari, kafe-restoranlarni sayr qilib, hordiq onlarini ham zavqli, ham mazmunli o’tkazishlari mumkin'
            )}
          </p>
        </div>
        <div className='tinchlikplaza_home_rectangel_col_4'>
          <div>
            <div>
              <h1>Tinchlik Plaza</h1>
              <h3>{t('Faqat sayyohlar uchunmi?')}</h3>
              <p>
                {t(
                  'Tinchlik Plazada nafaqat sayyohlar va mehmonlar balki shahar aholisi uchun ham barcha turdagi qulayliklar yaratilgan. Biz yuqori darajadagi xizmat hamoxangligida har bir mijozimizga unutilmas mehmondo’stlik va unutilmas onlarni hadya etamiz'
                )}
              </p>
            </div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className='tinchlikplaza_home_rectangel_col_3'>
          <p>{t('Mehmonlar uchun qulayliklar')}</p>
          <div>
            <div>
              <div>
                <img src={Spa} alt='Spa' />
              </div>
              <div>
                <h1>{t('SPA-salon')}</h1>
                <p>
                  {t(
                    '“Sauna & SPA salon massage” ko‘plab sog‘lomlashtiruvchi dasturlarni taqdim etadi klassik uslubdagi bali massaji, shiatsu, issiq toshlar bilan massaj, aromaterapiya va boshqa SPA-dasturlar.'
                  )}
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src={Restourant} alt='Restourant' />
              </div>
              <div>
                <h1>{t('Restoran')}</h1>
                <p>
                  {t(
                    'Bayram, oilaviy tadbirlar va baxtli kunlaringizni nishonlash uchun eng yaxshi imkoniyat! Ko‘ngilochar shou-dasturlar, jonli musiqa, bosh oshpazdan maxsus menyu hamda har bir mijozga individual yondashuv.'
                  )}
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src={gym} alt='gym' />
              </div>
              <div>
                <h1>{t('Rustik’s PUB')}</h1>
                <p>
                  {t(
                    'Navoiy shahridagi irlandcha uslubdagi birinchi va yagona PUB. Biz vaqtingizni mazmunli va unutilmas onlarga boy bo‘lishiga kafolat beramiz!'
                  )}
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src={Parking} alt='Parking' />
              </div>
              <div>
                <h1>{t('Avtoturargoh')}</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod
                  purus neque ultrices sit augue placerat. Diam vitae eget donec
                  ullamcorper nisi sagittis. Leo, odio nec
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src={Fitnes} alt='Fitnes' />
              </div>
              <div>
                <h1>{t('Fitnes-maskan')}</h1>
                <p>
                  {t(
                    'Biz sizga eng yaxshi fitnes xizmatni taqdim etamiz! Sport bilan shug‘ullaning va bo‘sh vaqtingizni TINCHLIK PLAZAda o‘tkazing!'
                  )}
                </p>
              </div>
            </div>
            <div>
              <div>
                <img src={Love} alt='Love' />
              </div>
              <div>
                <h1>{t('Love Story')}</h1>
                <p>
                  {t(
                    "Romantik sevgingizni kamalak ranglarida ifoda eting! “TINCHLIK PLAZA” mehmonxonasida sehrli va unutilmas to'y fotosessiyani o'tkazing!"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='tinchlikplaza_home_rectangel_col_5'>
          <Swiper
            className='tinchlikplaza_home_rectangel_col_5_swiper'
            direction={'vertical'}
            parallax={true}
            grabCursor={true}
            loop={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            <SwiperSlide>
              <div class='swiper-image' data-swiper-parallax-y='-20%'>
                <div
                  class='swiper-image-inner swiper-image-left swiper-image-one'
                  style={{
                    backgroundImage: `url(${img1})`,
                  }}
                ></div>
              </div>
              <div className='swiper-text'>
                <div>
                  <h1>Tinchlik Plaza</h1>
                  <h3>{t('Mijozlariga qanday turdagi xonalarni taklif etadi?')}</h3>
                  <p>
                    {t(
                      'Biz har bir mijozimiz taklif va istagiga mos bo’lgan, did bilan bezatilgan, zamonaviy va barcha qulayliklarga ega xonalarni taklif etamiz. Mehmonxonada siz nafaqat qisqa muddatga tashrif buyurishingiz, balki uzoq vaqt davomida yashashingiz uchun ham moslashtirilgan'
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class='swiper-image' data-swiper-parallax-y='-20%'>
                <div
                  class='swiper-image-inner swiper-image-left swiper-image-one'
                  style={{
                    backgroundImage: `url(${img2})`,
                  }}
                ></div>
              </div>
              <div className='swiper-text'>
                <div>
                  <h1>Tinchlik Plaza</h1>
                  <h3>{t('Mijozlariga qanday turdagi xonalarni taklif etadi?')}</h3>
                  <p>
                    {t(
                      'Biz har bir mijozimiz taklif va istagiga mos bo’lgan, did bilan bezatilgan, zamonaviy va barcha qulayliklarga ega xonalarni taklif etamiz. Mehmonxonada siz nafaqat qisqa muddatga tashrif buyurishingiz, balki uzoq vaqt davomida yashashingiz uchun ham moslashtirilgan'
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className='prev' ref={navigationPrevRef}>
            <svg
              width='57'
              height='56'
              viewBox='0 0 57 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M49.001 28H15.751'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M31.501 12.25L15.751 28L31.501 43.75'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M56 8.75V47.25'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
          <div className='next' ref={navigationNextRef}>
            <svg
              width='57'
              height='56'
              viewBox='0 0 57 56'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.99902 28H41.249'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M25.499 12.25L41.249 28L25.499 43.75'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M1 8.75V47.25'
                stroke='#381955'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
        </div>
        <div className='tinchlikplaza_home_rectangel_col_6'>
          <h1>{t('Biz haqimizda mijozlarimiz fikri')}</h1>
          <Carousel slides={slides} autoplay={false} interval={1000} />
        </div>
      </div>
    </div>
  )
}

export default Home
