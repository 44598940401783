import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useTranslation } from 'react-i18next'
import './xonalar.scss'
import Xona from './../../../img/971.png'
import Plaza_logo from './../../../img/plaza_logo.png'
import Group156 from './../../../img/Group156.png'
import Group157 from './../../../img/Group157.png'
import Single from './../../../img/Single.png'
import APARTAMENTS from './../../../img/APARTAMENTS.png'
import STANDARDTWIN from './../../../img/STANDARDTWIN.png'
import TRIPLE from './../../../img/TRIPLE.png'
import JUNIORSUITE from './../../../img/JUNIORSUITE.png'

gsap.registerPlugin(ScrollTrigger)
function Xonalar() {
  const { t } = useTranslation()
  useEffect(() => {
    const tinchlikplaza_xonalar_col_1 = document.querySelectorAll(
      '.tinchlikplaza_xonalar_col_1>div'
    )
    tinchlikplaza_xonalar_col_1.forEach(tinchlikplaza_xonalar_col_1_timeline)
    function tinchlikplaza_xonalar_col_1_timeline(project, index) {
      gsap.to(project, {
        duration: 1,
        y: 0,
        x: 0,
        ease: 'ease-in',
        scrollTrigger: {
          trigger: project,
          markers: false,
          scrub: 1,
          start: 'top 120%',
          end: 'top 200%',
        },
      })
      gsap.to('.Group156', {
        duration: 1,
        scale: '1',
        ease: 'ease-in',
        scrollTrigger: {
          trigger: '.Group156',
          markers: false,
          scrub: 1,
          start: 'top 100%',
          end: 'top 150%',
        },
      })
      gsap.to('.Group157', {
        duration: 1,
        scale: '1',
        ease: 'ease-in',
        scrollTrigger: {
          trigger: '.Group157',
          markers: false,
          scrub: 1,
          start: 'top 100%',
          end: 'top 150%',
        },
      })
    }
  })
  return (
    <div className='tinchlikplaza_xonalar'>
      <img src={Group156} alt='Group156' className='Group156' />
      <img src={Group157} alt='Group157' className='Group157' />
      <h1>{t('Xonalar')}</h1>
      <div className='tinchlikplaza_xonalar_col_1'>
        <div>
          <img src={Xona} alt='Xona' />
          <div>
            <h1>{t('IKKI KISHILIK XONA')}</h1>
            <p>
              {t(
                'Umumiy maydoni 18 m² bo‘lgan ushbu xona nozik did bilan jihozlangan bo‘lib unda mijozlar qulayligi uchun barcha sharoitlar yaratilgan. Yoqimli ranglar, takrorlanmas dizayn va yuqori sifatli xona bezaklari oila davrasida maroqli hordiq olishingiz uchun ayni muddaodir.'
              )}
            </p>
            <Link to='/xonalar/5'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={Single} alt='Single' />
          <div>
            <h1>{t('BIR KISHILIK XONA')}</h1>
            <p>
              {t(
                'Xizmat safari bilan kelgan mehmonlar uchun ayni muddao - siz bemalol dam olib, muhim uchrashuv oldidan fikrlaringizni jamlashingiz va yoqimli onlardan bahramand bo‘lishingiz mumkin. 16 m² shinam xona, go‘zal va betakror manzara sizga unutilmas lahzalarni hadya etadi.'
              )}
            </p>
            <Link to='/xonalar/6'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={Xona} alt='Xona' />
          <div>
            <h1>{t('LUKS XONA')}</h1>
            <p>
              {t(
                'Maydoni 35 m² boʼlgan hashamatli xonalar vaqtingizni foydali va mazmunli oʼtkazishingiz uchun eng yaxshi tanlovdir. Xonaga kirar ekansiz, tabiiy yorugʼlik va ranglar uygʼunligidagi oʼzgacha shinamlikni his etasiz. Lyuks toifadagi xonaning balkon qismidan esa nafaqat Navoiy shahrining goʼzal manzaralaridan bahramand boʼlasiz, balki oʼzingizga unutilmas onlarni hadya etasiz.'
              )}
            </p>
            <Link to='/xonalar/7'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={APARTAMENTS} alt='APARTAMENTS' />
          <div>
            <h1>{t('APARTAMENTS')}</h1>
            <p>
              {t(
                'Mehmonxonaning eng hashamatli xonalari – shubhasiz, apartamentlardir. 60 m² ichki maydonga ega keng xonalar tashrif buyuruvchi mehmonlarning nozik didiga mos ravishda jihozlangan. Apartament xonalarda do‘stona uchrashuvlar yoki muzokaralar uchun mo‘ljallangan katta o‘lchamdagi stol, oshxona, ish joyi, yuvinish xonasi, shuningdek, keng va yorug‘ yotoqxona mavjud. Bundan tashqari mijozlarga kengaytirilgan xizmatlar paketi, qo‘shimcha bonuslar va imkoniyatlar ham taqdim etiladi.'
              )}
            </p>
            <Link to='/xonalar/8'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={STANDARDTWIN} alt='Xona' />
          <div>
            <h1>{t('IKKI KISHILIK XONA2')}</h1>
            <p>
              {t(
                'Ushbu turdagi xonalar 24 m² ichki maydonga ega bo‘lib, oilaviy yoki ikki kishi bo‘lib tashrif buyurishingiz uchun mo‘ljallangan. Xonada ikkita alohida yotoq, ish joyi, shkaf va yuvinish xonasi mavjud. Barcha qulayliklarga ega xonada siz dam olish vaqtingizni zavqli va foydali o‘tkazasiz.'
              )}
            </p>
            <Link to='/xonalar/9'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={TRIPLE} alt='Xona' />
          <div>
            <h1>{t('UCH KISHILIK XONA')}</h1>
            <p>
              {t(
                'Maydoni 28 m² bo‘lgan xona yaqin do‘stlar, hamkasblar va ahil oila vakillari uchun ayni muddaodir. Xona ichidagi jihozlar mehmonlarimiz qulayligi va maroqli hordigʼi uchun moʼljallangan boʼlib, yuqori darajadagi mehmonxona taqdim etishi mumkin boʼlgan barcha afzalliklarga egadir.'
              )}
            </p>
            <Link to='/xonalar/1'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
        <div>
          <img src={JUNIORSUITE} alt='Xona' />
          <div>
            <h1>{t('YARIMLUKS XONA')}</h1>
            <p>
              {t(
                '30 m² maydonga ega keng va yorug‘ xona klassik uslubda bezatilgan bo‘lib, kichik oilalar va sinchkov sayyohlar uchun mo‘ljallangan. Hashamatli, o‘zgacha dizayn va uslubda yaratilgan xonalar ish va dam olishingiz uchun eng qulay maskan bo‘lishiga ishonamiz.'
              )}
            </p>
            <Link to='/xonalar/2'>
              {t('To’liq ko’rish')}
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4375 11H18.5625'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M12.375 4.8125L18.5625 11L12.375 17.1875'
                  stroke='#5338A9'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Link>
            <img src={Plaza_logo} alt='Plaza_logo' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Xonalar
