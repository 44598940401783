import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './Home/home'
import Xonalar from './Xonalar/xonalar'
import Apartaments from './Xonalar/Apartaments/apartaments'
import Restaran from './Restanart/restaran'
import Fitness from './FItness/fitness'
import Media from './Media/media'

function Container() {
  return (
    <Switch>
      <Route path='/' exact>
        <Home />
      </Route>
      <Route path='/xonalar' exact>
        <Xonalar />
      </Route>
      <Route path='/xonalar/:id' exact>
        <Apartaments />
      </Route>
      <Route path='/restorant' exact>
        <Restaran />
      </Route>
      <Route path='/fitnes' exact>
        <Fitness />
      </Route>
      <Route path='/media' exact>
        <Media />
      </Route>
    </Switch>
  )
}

export default Container
