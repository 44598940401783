import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './gallery.scss'
import Rectangle61 from './../../../../img/Rectangle61.png'
import Rectangle62 from './../../../../img/Rectangle62.png'
import Rectangle78 from './../../../../img/Rectangle78.png'

gsap.registerPlugin(ScrollTrigger)
function Gallery() {
  useEffect(() => {
    const tinchlikplaza_gallery = document.querySelectorAll(
      '.tinchlikplaza_gallery>img'
    )
    tinchlikplaza_gallery.forEach(tinchlikplaza_gallery_timeline)
    function tinchlikplaza_gallery_timeline(project, index) {
      gsap.to(project, {
        duration: 1,
        y: 0,
        x: 0,
        ease: 'ease-in',
        scrollTrigger: {
          trigger: project,
          markers: false,
          scrub: 1,
          start: 'top 100%',
          end: 'top 150%',
        },
      })
    }
  })
  return (
    <div className='tinchlikplaza_gallery'>
      <img src={Rectangle61} alt='Rectangle61' />
      <img src={Rectangle62} alt='Rectangle62' />
      <img src={Rectangle78} alt='Rectangle78' />
      <img src={Rectangle61} alt='Rectangle61' />
      <img src={Rectangle62} alt='Rectangle62' />
      <img src={Rectangle78} alt='Rectangle78' />
      <img src={Rectangle61} alt='Rectangle61' />
      <img src={Rectangle62} alt='Rectangle62' />
      <img src={Rectangle78} alt='Rectangle78' />
      <img src={Rectangle61} alt='Rectangle61' />
      <img src={Rectangle62} alt='Rectangle62' />
      <img src={Rectangle78} alt='Rectangle78' />
      <img src={Rectangle61} alt='Rectangle61' />
      <img src={Rectangle62} alt='Rectangle62' />
      <img src={Rectangle78} alt='Rectangle78' />
    </div>
  )
}

export default Gallery
