import React from 'react';
import 'antd/dist/antd.css';
import Menu from './components/Menu/menu'
import Container from './components/Container/container'
import Footer from './components/Footer/footer'
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollIntoView from './ScrollIntoView';


function App() {
  return (
    <Router>
      <ScrollIntoView>
        <Menu />
        <Container />
        <Footer />
      </ScrollIntoView>
    </Router>
  );
}

export default App;
