import React from 'react'
import { Carousel } from '3d-react-carousal'
import './apartaments.scss'
import Restorant from './../../../../img/Restorant.png'
import Restorant2 from './../../../../img/1012.png'
import Swimmer from './../../../../img/swimmer.svg'
import Bath from './../../../../img/bath.svg'
import Snowflake from './../../../../img/Snowflake.svg'
import Monitor from './../../../../img/Monitor.svg'
import SpeakerSlash from './../../../../img/SpeakerSlash.svg'
import WifiHigh from './../../../../img/WifiHigh.svg'
import Car from './../../../../img/Car.svg'

let slides = [
  <img src={Restorant2} alt='Restorant2' />,
  <img src={Restorant2} alt='Restorant2' />,
  <img src={Restorant2} alt='Restorant2' />,
  <img src={Restorant2} alt='Restorant2' />,
  <img src={Restorant2} alt='Restorant2' />,
]
function Apartaments() {
  return (
    <div className='tinchlikplaza_apartaments'>
      <h1>APARTAMENTS</h1>
      <div className='tinchlikplaza_apartaments_col_1'>
        <img src={Restorant} alt='Restorant' />
        <div></div>
        <p>
          Это самые роскошные номера в гостинице TINCHLIK PLAZA. Просторные и
          элегантные апартаменты размером 60м2 выполнены в классическом стиле.
          Уникальный дизайн интерьера подчеркнёт особый статус гостей и идеально
          подойдёт для семей с детьми. Номер состоит из просторной гостиной с широким
          столом для дружественных встреч или переговоров, совмещённой с кухонной
          зоной, функционального кабинета для работы, ванной комнаты, а также
          просторной и светлой спальни. Апартаменты – это престижные и элитные номера
          в сочетании с расширенным пакетом услуг, бонусных сервисов и дополнительных
          опций.
        </p>
      </div>
      <div className='tinchlikplaza_apartaments_col_2'>
        <img src={Restorant2} alt='Restorant2' />
        <div>
          <div>
            <img src={Swimmer} alt='Swimmer' />
            <span>Вид на бассейн</span>
          </div>
          <div>
            <img src={Bath} alt='Bath' />
            <span>Собственная ванная комната</span>
          </div>
          <div>
            <img src={Snowflake} alt='Snowflake' />
            <span>Кондиционер</span>
          </div>
          <div>
            <img src={Monitor} alt='Monitor' />
            <span>Телевизор с плоским экраном</span>
          </div>
          <div>
            <img src={SpeakerSlash} alt='SpeakerSlash' />
            <span>Звукоизоляция</span>
          </div>
          <div>
            <img src={WifiHigh} alt='WifiHigh' />
            <span>Бесплатный Wi-Fi</span>
          </div>
          <div>
            <img src={Car} alt='Car' />
            <span>Бесплатная парковка</span>
          </div>
        </div>
      </div>
      <div className='tinchlikplaza_apartaments_col_3'>
        <Carousel slides={slides} autoplay={false} interval={1000} />
      </div>
      <button>
        <span>Band qilish</span>
      </button>
    </div>
  )
}

export default Apartaments
